import Tippy from '@tippyjs/react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const tooltipTypeStyles = {
	warning: {
		iconClass: 'fal fa-exclamation-triangle',
		iconColor: '#fa9c36',
		textColor: '#613400',
		backgroundColor: '#fff4e5',
		borderColor: '#fa9c36'
	},

	success: {
		iconClass: 'fal fa-check-circle',
		iconColor: '#148E45',
		textColor: '#435c4e',
		backgroundColor: '#e8f5e9',
		borderColor: '#148E45'
	},

	error: {
		iconClass: 'fal fa-exclamation-circle',
		iconColor: '#f5222d',
		textColor: '#a8071a',
		backgroundColor: '#fdecea',
		borderColor: '#f5222d'
	},

	info: {
		iconClass: 'fal fa-info-circle',
		iconColor: '#1890ff',
		textColor: '#0050b3',
		backgroundColor: '#e6f7ff',
		borderColor: '#1890ff'
	},

	default: {
		iconClass: '',
		iconColor: 'inherit',
		textColor: '#fff',
		backgroundColor: 'inherit',
		borderColor: 'inherit'
	}
};

const getValidatedPlacement = (placement) => {
	return ['top', 'right', 'bottom', 'left'].includes(placement) ? placement : 'top';
};

const getTooltipStyle = (type) => tooltipTypeStyles[type] ?? tooltipTypeStyles.default;

/**
 * Note: The base styles for Tippy.js tooltips are loaded and can be customized in "src/styles/tippyStyles.js".
 */

/**
 * Tooltip component
 *
 * @param {Object} props - Component props
 * @param {string | React.ReactNode} props.content - Content displayed inside the tooltip (string or React node)
 * @param {React.ReactNode} [props.children] - Children elements to wrap with the tooltip
 * @param {string} [props.type='default'] - Tooltip style type
 * @param {string} [props.placement='top'] - Tooltip placement
 * @param {string} [props.trigger='mouseenter focus'] - Tooltip trigger
 */
export const Tooltip = ({
	content,
	children,
	type,
	placement = 'top',
	trigger = 'mouseenter focus'
}) => {
	const validatedPlacement = getValidatedPlacement(placement);

	if (validatedPlacement !== placement) {
		window.console.warn(`Tooltip: invalid placement: ${placement}. Defaulting to "top".`);
	}

	const tooltipStyle = getTooltipStyle(type);

	return (
		<Tippy
			animation="scale"
			delay={[100, 200]}
			trigger={trigger}
			placement={validatedPlacement}
			content={<TooltipContent styleConfig={tooltipStyle}>{content}</TooltipContent>}
		>
			{children ?? (
				<TooltipIcon
					className={tooltipStyle.iconClass || 'fal fa-question-square'}
					styleConfig={tooltipStyle}
				/>
			)}
		</Tippy>
	);
};

Tooltip.propTypes = {
	content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	children: PropTypes.node,
	type: PropTypes.string,
	placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
	trigger: PropTypes.string,
	ariaLabel: PropTypes.string
};

const TooltipContent = ({ styleConfig, children }) => {
	const { iconClass } = styleConfig;

	return (
		<TooltipContainer styleConfig={styleConfig}>
			{iconClass && <TooltipIcon className={iconClass} styleConfig={styleConfig} />}

			{children}
		</TooltipContainer>
	);
};

TooltipContent.propTypes = {
	styleConfig: PropTypes.object.isRequired,
	children: PropTypes.node.isRequired
};

const TooltipIcon = styled.span.attrs(({ className, styleConfig }) => ({
	className: styleConfig.iconClass || className,
	color: styleConfig.iconColor || 'inherit'
}))`
	font-size: 20px;
	color: ${({ color }) => color};
	text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
	cursor: pointer;
`;

const TooltipContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 10px;
	padding: 8px 10px;
	border-radius: 3px;
	font-size: 14px;
	text-align: justify;
	background-color: #ffffff;
	${({ styleConfig }) => {
		const { textColor, backgroundColor, borderColor } = styleConfig;
		let borderLeft = borderColor ? `10px solid ${borderColor}` : 'none';
		return css`
			color: ${textColor};
			background-color: ${backgroundColor};
			border-left: ${borderLeft};
		`;
	}}
`;
