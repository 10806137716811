import axios from 'axios';

import { createErrorAlert } from 'features/alertService';

const adminPanelIsLoading = () => {
	return {
		type: 'FETCH_ADMIN_PANEL_REQUEST'
	};
};
const adminPanelRequested = (data) => {
	return {
		type: 'FETCH_ADMIN_PANEL_SUCCESS',
		payload: data
	};
};
const adminPanelListError = (error) => {
	return {
		type: 'FETCH_ADMIN_PANEL_ERROR',
		payload: error
	};
};

const updateBlockStatusIsLoading = () => {
	return {
		type: 'FETCH_UPDATE_BLOCK_STATUS_REQUEST'
	};
};
const updateBlockStatusRequested = (data) => {
	return {
		type: 'FETCH_UPDATE_BLOCK_STATUS_SUCCESS',
		payload: data
	};
};
const updateBlockStatusError = (error) => {
	return {
		type: 'FETCH_UPDATE_BLOCK_STATUS_ERROR',
		payload: error
	};
};

export const fetchAdminPanelList = ({
	includeDateRange,
	startDate,
	endDate,
	filter = '',
	page = 1,
	limit = 10
}) => {
	return async (dispatch) => {
		dispatch(adminPanelIsLoading());
		try {
			const {
				data: { list = [], totalAll = 0 }
			} = await axios.get('admin-panel/users', {
				params: {
					page,
					limit,
					includeDateRange,
					startDate,
					endDate,
					filter
				}
			});
			dispatch(adminPanelRequested({ list, totalAll }));
		} catch (e) {
			if (e?.response?.data?.error) {
				dispatch(createErrorAlert({ message: e.response.data.error }));
			}
			dispatch(adminPanelListError(e));
		}
	};
};

export const updateBlockStatus = (id, status) => {
	return async (dispatch) => {
		dispatch(updateBlockStatusIsLoading());
		try {
			const resStatus = status === 'block' ? 'unblock' : 'block';
			const {
				data: { newStatus }
			} = await axios.patch(`admin-panel/users/${id}/block-status`, { status: resStatus });
			dispatch(updateBlockStatusRequested({ id, newStatus }));
		} catch (e) {
			if (e?.response?.data?.error) {
				dispatch(createErrorAlert({ message: e.response.data.error }));
			}
			dispatch(updateBlockStatusError(e));
		}
	};
};
