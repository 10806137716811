import axios from 'axios';

const subscriptionPlansIsLoading = () => {
	return {
		type: 'FETCH_SUBSCRIPTION_PLANS_REQUEST'
	};
};

const subscriptionPlansRequested = (plansData) => {
	return {
		type: 'FETCH_SUBSCRIPTION_PLANS_SUCCESS',
		payload: plansData
	};
};

const subscriptionPlansError = (error) => {
	return {
		type: 'FETCH_SUBSCRIPTION_PLANS_ERROR',
		payload: error
	};
};

const fetchSubscriptionPlans = () => {
	return async (dispatch) => {
		dispatch(subscriptionPlansIsLoading());

		try {
			const {
				data: { data: plans = [], meta = {} }
			} = await axios.get(`/subscription-plans`);

			dispatch(subscriptionPlansRequested({ plans, meta }));
		} catch (e) {
			dispatch(subscriptionPlansError(e));
		}
	};
};

export { fetchSubscriptionPlans };
