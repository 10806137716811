import { css } from 'styled-components';
import 'tippy.js/animations/scale.css';
import 'tippy.js/dist/tippy.css';

export const TippyStyles = css`
	.tippy-box {
		padding: 3px;
		border-radius: 6px;
		line-height: normal;
		background-color: #333;
		box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.6);
	}
	.tippy-content {
		padding: 0;
		border-radius: 3px;
	}
`;
