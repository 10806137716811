import { formatCurrency, formatNumber } from 'utils/formatter';

export const freeJobsEmojiRanges = [
	{ max: 100, emoji: '😀' },
	{ max: 1000, emoji: '🤩' },
	{ max: 10000, emoji: '🤯' },
	{ max: Infinity, emoji: '🚀' }
];

/**
 * Returns the corresponding emoji based on the number of free jobs.
 *
 * @param {number} freeJobs - The number of free jobs.
 * @returns {string} - Emoji representing the range of free jobs.
 */
export const getFreeJobsEmoji = (freeJobs) =>
	freeJobsEmojiRanges.find((range) => freeJobs <= range.max)?.emoji || '';

/**
 * Generates a list of features based on the plan configuration.
 *
 * @param {Object} plan - The plan details.
 * @param {number} plan.freeJobs - The number of free jobs included in the plan.
 * @param {string} plan.freeJobsExceedAction - Action to take when free jobs are exceeded ('charge' or 'block').
 * @param {number} plan.extraJobCost - Cost for each additional job.
 * @param {number|null} plan.jobsLimit - Maximum number of jobs allowed per month (null represents no limit).
 * @returns {string[]} - List of formatted feature descriptions for the plan.
 */
export const getFeaturesList = (plan) => {
	if (!plan) return [];

	const { freeJobs, freeJobsExceedAction, extraJobCost, jobsLimit } = plan;

	const features = [];

	if (freeJobsExceedAction === 'charge') {
		features.push('Unlimited self-browser prints');
	}

	if (Number.isFinite(freeJobs) && freeJobs > 0) {
		const emoji = getFreeJobsEmoji(freeJobs);

		features.push(`${formatNumber(freeJobs)} cloud prints free ${emoji}`);
	}

	if (freeJobsExceedAction === 'charge' && extraJobCost) {
		features.push(`+${formatCurrency(extraJobCost)} each additional cloud print`);
	}

	features.push(
		jobsLimit === null ? 'No printing limits' : `${formatNumber(jobsLimit)} prints per month`
	);

	return features;
};
