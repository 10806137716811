/**
 * Validates the billing details, including the customer's name and optionally their address.
 *
 * @param {Object} options - The options for validation.
 * @param {Object} options.billingDetails={} - The billing details provided by the customer.
 * @param {string} options.billingDetails.name - The customer's name as it appears on the card.
 * @param {Object} [options.billingDetails.address] - The customer's billing address (optional).
 * @param {string} [options.billingDetails.address.line1] - The first line of the customer's address.
 * @param {string} [options.billingDetails.address.city] - The city of the customer's address.
 * @param {string} [options.billingDetails.address.state] - The state of the customer's address.
 * @param {string} [options.billingDetails.address.country] - The country of the customer's address.
 * @param {boolean} [options.validateAddress=false] - Whether to validate the address fields.
 *
 * @returns {{isValid: boolean, message: string}} - The result of the validation
 *
 */
export const validateBillingDetails = ({ billingDetails = {}, validateAddress = false }) => {
	const { name, address } = billingDetails;

	if (!name?.trim()) {
		return { isValid: false, message: 'The name on the card is required. Please fill it in' };
	}

	if (validateAddress) {
		const requiredFields = [
			{ field: 'line1', label: 'Address 1' },
			{ field: 'city', label: 'City' },
			{ field: 'state', label: 'State' },
			{ field: 'country', label: 'Country' }
		];

		for (const { field, label } of requiredFields) {
			if (!address?.[field]?.trim()) {
				return { isValid: false, message: `${label} field cannot be empty` };
			}
		}
	}

	return { isValid: true, message: 'The billing details are valid' };
};
