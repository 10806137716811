import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { useJobsCount } from 'hooks/useJobsCount';

import { NoticeBox } from './style';

const PrintStatusBanner = ({ currentPlan }) => {
	const { perMonth, total, loading, error } = useJobsCount();

	if (loading || error || !currentPlan) return null;

	const { isFree, jobsLimit, freeJobsLeft, freeJobsExceedAction } = currentPlan;

	if (isFree) {
		const bannerMessage = `${jobsLimit} print limit has been reached for the trial period`;

		return checkLimitAndRender(total, jobsLimit, bannerMessage);
	}

	if (freeJobsExceedAction === 'block') {
		const bannerMessage = 'Free print jobs limit has been reached';

		return checkLimitAndRender(perMonth, freeJobsLeft, bannerMessage);
	}

	if (freeJobsExceedAction === 'charge') {
		if (jobsLimit == null) return null;

		const bannerMessage = 'Print plan transactions limit has been reached for the month';

		return checkLimitAndRender(perMonth, jobsLimit, bannerMessage);
	}

	return null;
};

export default PrintStatusBanner;

const checkLimitAndRender = (printed, limit, message) => {
	if (!message) return null;

	if (Number(printed) >= Number(limit)) {
		return (
			<PrintPausedBox>
				<b>PRINT SERVICE PAUSED:</b> {message}
				<br />
				<Link to="subscription-plans">Upgrade your plan</Link>
			</PrintPausedBox>
		);
	}

	return null;
};

const PrintPausedBox = styled(NoticeBox)`
	background-color: #dc2626;
`;
