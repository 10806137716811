import styled from 'styled-components';

import IncludedFeatures from './IncludedFeatures';
import * as Styles from './style';

const CONTACT_SALES_URL = 'https://getbizprint.com/contact-sales/';

const features = [
	'Credit based usage plans',
	'High-volume usage discounts',
	'Large agency account usage',
	'Enterprise level account usage'
];

const ContactSalesCard = () => {
	return (
		<Styles.PlanContainer>
			<Styles.PlanInfoCard isPrivate>
				<CardHeading>Simple, flexible pricing options</CardHeading>

				<ActionTitle>Let's talk</ActionTitle>

				<IncludedFeatures heading="Exclusive Features:" customFeatures={features} />
			</Styles.PlanInfoCard>

			<LinkButton href={CONTACT_SALES_URL} target="_blank" rel="noopener noreferrer">
				Contact Sales
			</LinkButton>
		</Styles.PlanContainer>
	);
};

export default ContactSalesCard;

const CardHeading = styled(Styles.Name)`
	margin: 20px 0 10px;
	font: 600 15px 'Work Sans', 'sans-serif';
	text-shadow: none;
`;

const ActionTitle = styled(Styles.Price)`
	margin: 5px 0;
	font: 600 38px 'Work Sans', 'sans-serif';
`;

const LinkButton = styled(Styles.Button).attrs(({ isPrivate = true }) => ({
	as: 'a',
	isPrivate
}))`
	text-decoration: none;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
`;
