import styled, { css } from 'styled-components';

import FaIcon from 'components/ui/Icons';

import { getFeaturesList } from 'utils/planFeaturesHelpers';

const defaultFeatures = {
	Plus: ['Remove Branding', 'Sound Notifications'],
	Pro: [
		'Remove Branding',
		'Sound Notifications',
		'Mapping Add-ons',
		'Send as WhatsApp, Email, SMS'
	],
	Growth: [
		'Remove Branding',
		'Sound Notifications',
		'Mapping Add-ons',
		'Send as WhatsApp, Email, SMS'
	]
};

const IncludedFeatures = ({ plan, isCanceling, customFeatures, heading = 'Included:' }) => {
	const features = customFeatures || [
		...getFeaturesList(plan),
		...(defaultFeatures[plan?.name] || [])
	];

	if (!Array.isArray(features) || !features.length) return null;

	return (
		<FeaturesContainer>
			<h3>{heading}</h3>

			<FeaturesList features={features} isCanceling={isCanceling} />
		</FeaturesContainer>
	);
};

const FeaturesList = ({ features, isCanceling }) => (
	<FeaturesListItems isCanceling={isCanceling}>
		{features.map((feature) => (
			<li key={feature} title={feature}>
				<FaIcon icon="check" type="duotone" size="12px" /> {feature}
			</li>
		))}
	</FeaturesListItems>
);

export default IncludedFeatures;

const FeaturesContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;
	width: 100%;
	color: #4b5563;

	h3 {
		margin: 0;
		padding: 0;
		font-size: 0.9rem;
		font-weight: 600;
	}
`;

const FeaturesListItems = styled.ul`
	list-style: none;
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin: 0;
	padding: 0;

	li {
		min-height: 15px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		padding-left: 2px;
		font-size: 13px;
		font-weight: 400;

		i::after {
			margin-right: 2px;
			color: #60a5fa;
			z-index: inherit;
		}
	}

	${({ isCanceling }) =>
		isCanceling &&
		css`
			max-height: 130px;
			overflow-y: auto;

			::-webkit-scrollbar {
				width: 7px;
			}

			::-webkit-scrollbar-track {
				background-color: #e2e8f0;
				border-radius: 3px;
			}

			::-webkit-scrollbar-thumb {
				background-color: #757575;
				border-radius: 3px;

				:hover {
					background-color: #a7a7a7;
				}
			}
		`}
`;
