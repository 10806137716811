import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import DashboardItem from 'components/ui/DashBoardItem';
import FaIcon from 'components/ui/Icons';

import { getSubscriptionTheme } from 'styles/constants';

export const ItemLink = styled(Link)`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	justify-content: space-between;
	padding: 1rem 0 1rem 1.5rem;
	text-decoration: none;
	color: black;
`;

export const CreateLink = styled(Link)`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 25%;
	max-width: 100px;
	border: 0;
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	font-size: 3rem;
	color: #fff;
	background-color: #2d9631;
	text-decoration: none;

	&:hover {
		background-color: #3ea742;
		cursor: pointer;
	}
`;

export const ItemContainer = styled(DashboardItem)`
	flex-flow: row;
	padding: 0;

	&:nth-child(2) a {
		padding: 0;
	}

	${({ planName, isPrivate }) => {
		if (planName) {
			const { color, bgColor } = getSubscriptionTheme(planName, isPrivate);

			return css`
				background-color: ${color};
			`;
		}
	}}
`;

export const ItemHeader = styled.div`
	display: flex;
	align-items: center;

	h2 {
		font-size: 1.1rem;
		font-weight: normal;
		margin: 0;
	}
`;

export const ItemIcon = styled(FaIcon)`
	font-size: 34px;
	margin-right: 1rem;
`;
