import React from 'react';

import { createRoot } from 'react-dom/client';
import Modal from 'react-modal';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import App from 'components/App';
import Authorization from 'components/Authorization';
import BasicLayout from 'components/BasicLayout';
import ErrorBoundary from 'components/ErrorBoundary';
import UserStatus from 'components/UserStatus';

import { isElectronApp } from 'utils/loadingContext';

import ElectronApp from 'pages/Electron';

import { GlobalStyles } from 'styles/globalStyles';

import './setupAxios.js';
import store from './store';

Modal.setAppElement('#root');

const root = createRoot(document.getElementById('root'));

const AppRoutes = () => {
	if (isElectronApp()) return <ElectronApp />;

	return (
		<BasicLayout>
			<App />
		</BasicLayout>
	);
};

root.render(
	<React.StrictMode>
		<Provider store={store}>
			<Router>
				<Authorization>
					<UserStatus>
						<ErrorBoundary>
							<GlobalStyles />

							<AppRoutes />
						</ErrorBoundary>
					</UserStatus>
				</Authorization>
			</Router>
		</Provider>
	</React.StrictMode>
);
