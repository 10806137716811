import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';

const initialState = {
	loading: true,
	error: null,
	count: null,
	hasWebhookError: null,
	isPlanFree: null,
	freeApps: null,
	extraAppCost: null
};

const applicationsInfoSlice = createSlice({
	name: 'applications-info',
	initialState,
	reducers: {
		loading: (state) => {
			state.loading = true;
			state.error = null;
			state.count = null;
			state.hasWebhookError = null;
			state.isPlanFree = null;
			state.freeApps = null;
			state.extraAppCost = null;
		},

		handleData: (state, action) => {
			state.loading = false;
			state.error = null;
			state.count = action.payload.count;
			state.hasWebhookError = action.payload.hasWebhookError;
			state.isPlanFree = action.payload.isPlanFree;
			state.freeApps = action.payload.freeApps;
			state.extraAppCost = action.payload.extraAppCost;
		},

		handleError: (state, action) => {
			state.loading = false;
			state.error = action.payload;
			state.count = null;
			state.hasWebhookError = null;
			state.isPlanFree = null;
			state.freeApps = null;
			state.extraAppCost = null;
		}
	}
});

export default applicationsInfoSlice.reducer;

export const fetchApplicationsInfo = () => {
	return async (dispatch) => {
		dispatch(applicationsInfoSlice.actions.loading());

		try {
			const {
				data: { count = 0, hasWebhookError, isPlanFree, freeApps, extraAppCost }
			} = await axios.get(`applications/info`);

			dispatch(
				applicationsInfoSlice.actions.handleData({
					count,
					hasWebhookError,
					freeApps,
					extraAppCost,
					isPlanFree
				})
			);
		} catch (e) {
			dispatch(applicationsInfoSlice.actions.handleError(e));
		}
	};
};
