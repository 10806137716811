import styled, { css } from 'styled-components';

import { getSubscriptionTheme } from 'styles/constants';

export const PlanContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	min-width: 260px;
	width: 260px;
	border-radius: 15px;
	transition: all 500ms ease;

	:is(:hover, :focus-within) {
		transform: scale(1.05);
		box-shadow: 0 5px 10px -3px rgba(0, 0, 0, 0.32);
	}
`;

const freePlanStyles = css`
	justify-content: center;
	text-align: center;
`;

const activePlanStyles = (bgColor) => css`
	background-color: ${bgColor};
`;

export const PlanInfoCard = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	min-height: 320px;
	height: max(320px, 100%);
	padding: 10px;
	border: solid 2px #e2e8f0;
	border-bottom: none;
	border-radius: 10px 10px 0 0;
	background-color: #ffffff;

	${({ planName, isFree, isPrivate, isActive }) => {
		const { color, bgColor } = getSubscriptionTheme(planName, isPrivate);

		return css`
			color: ${color};
			border-color: ${color};
			${isFree && freePlanStyles};
			${isActive && activePlanStyles(bgColor)};
		`;
	}}
`;

const badgeStyles = {
	expiring: css`
		color: #3b3f45;
		background-color: #f3f4f6;
		border-color: #757575;
	`,

	popular: css`
		color: #374151;
		background-color: #ffedd5;
		border-color: #ffa244;
	`,

	personal: css`
		color: #d84315;
		background-color: #ffe8e5;
		border-color: #ff5d5b;
	`
};

export const PlanBadge = styled.div`
	position: absolute;
	top: 0;
	left: 50%;
	width: 75%;
	padding: 5px 0;
	border: 2px solid #adb5bd;
	border-radius: 8px;
	font-size: 13px;
	font-weight: 800;
	letter-spacing: 1px;
	text-align: center;
	text-transform: uppercase;
	color: #212529;
	background-color: #e9ecef;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	transform: translate(-50%, -50%);
	overflow: hidden;

	::after {
		content: '';
		position: absolute;
		top: 0;
		left: -100%;
		width: 100%;
		height: 100%;
		background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.65), transparent);
		transform: skewX(-20deg);
		animation: lightSweep 2.5s linear infinite;
	}

	${({ badgeType }) => badgeStyles[badgeType]};

	@keyframes lightSweep {
		0% {
			left: -100%;
		}
		100% {
			left: 100%;
		}
	}
`;

export const FreePlanDescription = styled.div`
	display: flex;
	flex-direction: column;
	gap: 15px;

	span {
		font-size: 20px;
		font-weight: 700;
		color: #a3aec2;
	}

	p {
		margin: 0;
		padding: 0 20px;
		font-size: 13px;
		font-weight: 400;
		color: #4b5563;
	}
`;

export const Name = styled.h2`
	margin: 15px 0 0;
	padding: 0;
	font: 600 30px 'Work Sans', 'sans-serif';
	color: inherit;
	text-shadow: 1px 1px 0 #cbd5e1;
	text-align: center;
`;

export const Price = styled.p`
	margin: 0;
	padding: 0;
	font: 600 48px 'Work Sans', 'sans-serif';
	color: #3f3f46;
	text-shadow: 2px 2px 0 #d1d5db;

	sub {
		position: relative;
		font-size: 18px;
		font-weight: 600;
		color: #a3b0c2;
		text-shadow: none;

		sup {
			position: absolute;
			top: 0;
			width: max(10px, 100%);
			font-size: 10px;
			font-weight: 500;
		}
	}
`;

export const ExpirationWrapper = styled.div`
	position: absolute;
	bottom: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 8px;
	width: 100%;
	margin: auto 0 0;
	padding: 5px;
	border-top: 2px solid #757575;
	font-size: 14px;
	font-weight: 500;
	text-align: center;
	color: #3b3f45;
	background-color: #f3f4f6;
`;

export const Button = styled.button`
	${({ planName, isPrivate, isActive, isInteracted }) => {
		const { color, bgColor } = getSubscriptionTheme(planName, isPrivate);

		return css`
			outline: none;
			width: 100%;
			min-height: 45px;
			height: 45px;
			border: 2px solid ${color};
			border-radius: 0 0 10px 10px;
			font-size: 1rem;
			font-weight: bold;
			background-color: ${color};
			color: #ffffff;
			cursor: pointer;

			${isActive &&
			css`
				background-color: #ffffff;
				color: ${color};
			`};

			${isInteracted &&
			!isActive &&
			css`
				background-color: #ffffff;
				color: ${color};
				border-top: none;
			`};

			:not(:disabled):is(:hover, :focus) {
				background-color: #ffffff;
				color: ${color};
			}

			:disabled {
				background-color: ${bgColor};
				color: ${color};
			}
		`;
	}}
`;
