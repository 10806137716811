export const subscriptionPlanColors = {
	Private: { color: '#ff5d5b', bgColor: '#fef2f2' },
	Trial: { color: '#7bc2d5', bgColor: '#f8fafc' },
	Basics: { color: '#a0befa', bgColor: '#eff6ff' },
	Plus: { color: '#b49efa', bgColor: '#f5f3ff' },
	Pro: { color: '#ffac33', bgColor: '#fff7ed' },
	Growth: { color: '#26a69a', bgColor: '#e0f2f1' },
	default: { color: '#666b6a', bgColor: '#e8f5e9' }
};

export const getSubscriptionTheme = (planName, isPrivate) => {
	return isPrivate
		? subscriptionPlanColors.Private
		: subscriptionPlanColors[planName] || subscriptionPlanColors.default;
};
