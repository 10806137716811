import { CardElement } from '@stripe/react-stripe-js';

import { createSetupIntent } from 'api/createSetupIntent';

import { validateBillingDetails } from 'utils/paymentMethods/validateBillingDetails';

/**
 * Sets up a payment method for a customer using Stripe.
 *
 * @param {Object} params - The parameters for setting up the payment method.
 * @param {Object} params.stripeInstance - The Stripe instance initialized with the publishable key.
 * @param {Object} params.stripeElements - The Stripe Elements instance for managing payment elements.
 * @param {Object} params.billingDetails - The customer's billing details, including name and address.
 * @param {string} params.billingDetails.name - The customer's name as it appears on the card.
 * @param {Object} [params.billingDetails.address] - The customer's optional billing address.
 * @param {boolean} [params.validateAddress=false] - Whether to validate the address fields.
 *
 * @returns {Promise<{setupIntent?: Object, error?: string}>} - Returns the setup intent on success or an error message on failure.
 */
export const setupPaymentMethod = async ({
	stripeInstance,
	stripeElements,
	billingDetails,
	validateAddress = false
}) => {
	try {
		if (!stripeInstance || !stripeElements) {
			return {
				error: `Unable to connect to payment service. Please try again or contact support`
			};
		}

		const { isValid, message } = validateBillingDetails({ billingDetails, validateAddress });

		if (!isValid) return { error: message };

		const { client_secret, error: setupIntentError } = await createSetupIntent();

		if (setupIntentError) return { error: setupIntentError };

		const cardElement = stripeElements.getElement(CardElement);

		const { error, setupIntent } = await stripeInstance.confirmCardSetup(client_secret, {
			payment_method: {
				card: cardElement,
				billing_details: billingDetails
			}
		});

		if (error) return { error: error.message };

		return { setupIntent };
	} catch (error) {
		return {
			error:
				error.message ||
				"We couldn't complete your payment setup. Please try again or contact support"
		};
	}
};
