const initialState = {
	plans: [],
	meta: {},
	loading: true,
	error: null
};

const subscriptionPlans = (state = initialState, action) => {
	switch (action.type) {
		case 'FETCH_SUBSCRIPTION_PLANS_REQUEST':
			return { ...state };

		case 'FETCH_SUBSCRIPTION_PLANS_SUCCESS':
			return {
				...state,
				plans: action.payload.plans,
				meta: action.payload.meta,
				loading: false,
				error: null
			};

		case 'FETCH_SUBSCRIPTION_PLANS_ERROR':
			return {
				...state,
				loading: false,
				error: action.payload
			};

		default:
			return state;
	}
};

export default subscriptionPlans;
