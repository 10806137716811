import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import Plan from 'components/Plan';
import ContactSalesCard from 'components/Plan/ContactSalesCard';
import { PrimeSpinner } from 'components/Spinners';

import { fetchSubscriptionPlans } from 'actions/subscription-plans';

const SubscribePlans = React.memo(({ activePlan, selectedPlan, selectPlan }) => {
	const dispatch = useDispatch();

	const { plans, meta, loading } = useSelector(({ subscriptionPlans }) => subscriptionPlans);

	useEffect(() => {
		dispatch(fetchSubscriptionPlans());
	}, [dispatch]);

	if (loading) return <PrimeSpinner message="Loading subscription" />;

	return (
		<div>
			<Title>Subscription Options</Title>

			<Container>
				{plans.map((plan) => {
					return (
						<Plan
							key={plan.id}
							planData={plan}
							activePlan={activePlan}
							selectedPlan={selectedPlan}
							selectPlan={selectPlan}
						/>
					);
				})}

				{!meta.hasPrivatePlans && <ContactSalesCard />}
			</Container>
		</div>
	);
});

export default SubscribePlans;

const Title = styled.h1`
	display: flex;
	justify-content: center;
	color: #585858;
`;

const Container = styled.div`
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	gap: 30px;
	width: 100%;
	margin: 0 auto;

	@media only screen and (min-width: 1100px) and (max-width: 1900px) {
		width: 1000px;
	}

	@media only screen and (min-width: 1901px) {
		width: 100%;
		justify-content: space-around;
	}
`;
