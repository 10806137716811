import styled from 'styled-components';

import FaIcon from 'components/ui/Icons';

const calculateTotalPrice = (total, discount) => {
	if (!discount) return { totalPrice: total.toFixed(2), discountValue: null };

	switch (discount.type) {
		case 'fixed':
			return {
				totalPrice: Math.max(0, total - discount.value).toFixed(2),
				discountValue: discount.value.toFixed(2)
			};

		case 'percent':
			const discountAmount = (total * discount.value) / 100;

			return {
				totalPrice: (total - discountAmount).toFixed(2),
				discountValue: discountAmount.toFixed(2)
			};

		default:
			return { totalPrice: total.toFixed(2), discountValue: null };
	}
};

const OrderSummary = ({ id, currPlanId, planName, isFree, jobsLimit, total, discount }) => {
	const { totalPrice, discountValue } = calculateTotalPrice(total, discount);

	const formatNum = (strNum = 0) => parseFloat(strNum).toLocaleString('en');

	return (
		<Summary>
			<SummaryField>
				<Description>
					{currPlanId && isFree
						? 'Downgrading subscription '
						: `${(currPlanId && !id) || currPlanId === id ? 'Subscribed ' : 'Subscribing '}`}
					to <b>{planName}</b>
					{!isFree && <sup aria-describedby="limits-disclaimer">*</sup>}
				</Description>

				<Value>${total.toFixed(2)}</Value>
			</SummaryField>

			{discountValue && (
				<SummaryField>
					<Description>
						{discount.code ? 'Applied code discount:' : 'Applied discount:'} <i>{discount.code}</i>
					</Description>

					<Value discountField>
						<i>-${discountValue}</i>
					</Value>
				</SummaryField>
			)}

			{discount?.duration && discount.duration > 1 && (
				<DiscountDurationInfo>
					<FaIcon icon="info" type="light" size="1rem" />
					Recurring discount valid for {discount.duration} billing periods
				</DiscountDurationInfo>
			)}

			<SummaryField>
				<Description>
					Total due at subscription start
					{!isFree && <sup aria-describedby="metered-disclaimer">†</sup>}
				</Description>

				<Value>${totalPrice}</Value>
			</SummaryField>

			{!isFree && (
				<>
					<Disclaimer id="limits-disclaimer">
						<sup>*</sup>
						{jobsLimit
							? `Plan limits ${formatNum(jobsLimit)} prints per month`
							: `No plan limits for printing`}
					</Disclaimer>

					<Disclaimer id="metered-disclaimer">
						<sup>†</sup>Cost per print usage billed monthly
					</Disclaimer>
				</>
			)}
		</Summary>
	);
};

export default OrderSummary;

const Summary = styled.div`
	color: #a3b0c2;
`;

const SummaryField = styled.div`
	display: flex;
	justify-content: space-between;
	padding-bottom: 0.5rem;

	i {
		color: rgb(83, 198, 145);
	}

	&:last-of-type {
		padding: 0.5rem 0 0;
		border-top: 1px solid #e2e8f0;
	}
`;

const Description = styled.span`
	font-size: 1rem;

	sup {
		top: -4px;
		margin-left: 3px;
		font-size: 0.9rem;
	}
`;

const Disclaimer = styled.p`
	margin: 0.5rem 0 0;
	font-size: 0.9rem;
	line-height: 1.7;

	sup {
		top: -4px;
		margin-right: 3px;
		font-size: 0.9rem;
	}
`;

const Value = styled.span`
	i {
		font-style: normal;
	}
`;

const DiscountDurationInfo = styled.div`
	display: flex;
	align-items: baseline;
	margin-bottom: 0.5rem;
	font-size: 0.85rem;
	font-style: italic;

	i {
		margin-right: 3px;
	}
`;
