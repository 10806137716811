import { PlanInfoCard } from './PlanInfoCard';
import { SelectButton } from './SelectButton';
import * as Styles from './style';

const Plan = ({ planData, activePlan, selectedPlan, selectPlan }) => {
	const handleSelectPlan = (event) => {
		event.preventDefault();

		selectPlan(planData);
	};

	return (
		<Styles.PlanContainer>
			<PlanInfoCard planData={planData} activePlan={activePlan} />

			<SelectButton
				planData={planData}
				activePlan={activePlan}
				selectedPlan={selectedPlan}
				onSelectPlan={handleSelectPlan}
			/>
		</Styles.PlanContainer>
	);
};

export default Plan;
