/**
 * Formats a given amount as currency.
 *
 * @param {number} amount - The amount to format.
 * @param {Object} options - Formatting options.
 * @param {string} options.locale - Locale for formatting (default is 'en-US').
 * @param {string} options.currency - Currency code (default is 'USD').
 * @param {number} options.minFractionDigits - Min fraction digits (0 for whole number, 2 otherwise).
 * @param {number} options.maxFractionDigits - Max fraction digits (default is 6).
 * @returns {string} - Formatted currency string.
 */
export const formatCurrency = (amount = 0, options = {}) => {
	const {
		locale = 'en-US',
		currency = 'USD',
		minFractionDigits = amount % 1 === 0 ? 0 : 2,
		maxFractionDigits = 6
	} = options;

	return new Intl.NumberFormat(locale, {
		style: 'currency',
		currency,
		minimumFractionDigits: minFractionDigits,
		maximumFractionDigits: maxFractionDigits
	}).format(amount);
};

/**
 * Formats a given number.
 *
 * @param {number} number - The number to format.
 * @param {Object} options - Formatting options.
 * @param {string} options.locale - Locale for formatting (default is 'en-US').
 * @param {number} options.minFractionDigits - Min fraction digits (0 for whole, 2 otherwise).
 * @param {number} options.maxFractionDigits - Max fraction digits (default is 6).
 * @returns {string} - Formatted number string.
 */
export const formatNumber = (number = 0, options = {}) => {
	const {
		locale = 'en-US',
		minFractionDigits = number % 1 === 0 ? 0 : 2,
		maxFractionDigits = 6
	} = options;

	return new Intl.NumberFormat(locale, {
		minimumFractionDigits: minFractionDigits,
		maximumFractionDigits: maxFractionDigits
	}).format(number);
};
