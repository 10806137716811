import { useEffect, useRef } from 'react';

import styled from 'styled-components';

import FaIcon from 'components/ui/Icons';

const TOOLTIP_BREAKPOINT = 500;
const SCROLLBAR_WIDTH = 20;

export const ConfirmationFormControl = ({
	isPlanFree,
	isConfirmed,
	extraAppCost,
	handleChanges
}) => {
	const tooltipRef = useRef(null);

	const hintText = isPlanFree
		? 'All Applications free during trial period'
		: 'Application fee applied to plans monthly usage billing';

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth >= TOOLTIP_BREAKPOINT) {
				const tooltip = tooltipRef.current;

				if (tooltip) {
					const tooltipParent = tooltip.parentElement.getBoundingClientRect();
					const tooltipWidth = tooltip.offsetWidth;
					const leftPosition = tooltipParent.left + tooltipParent.width / 2 - tooltipWidth / 2;

					if (tooltipWidth + leftPosition + SCROLLBAR_WIDTH >= window.innerWidth) {
						const left = window.innerWidth - (leftPosition + tooltipWidth) - SCROLLBAR_WIDTH;

						tooltip.style.left = Math.floor(left) + 'px';
					} else {
						tooltip.style.left = '50%';
					}
				}
			}
		};

		handleResize();

		window.addEventListener('resize', handleResize);

		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return (
		<Container>
			<ConfirmationCheckbox>
				<input
					type="checkbox"
					id="confirmAppAddition"
					onChange={handleChanges}
					value={isConfirmed}
				/>

				<label htmlFor="confirmAppAddition">
					Add application for ${extraAppCost} /mo to my subscription.
				</label>
			</ConfirmationCheckbox>

			<Tooltip>
				<FaIcon icon="info-square" size="18px" tabIndex="0" aria-describedby="hintText" />

				<p id="hintText" ref={tooltipRef}>
					{hintText}
				</p>
			</Tooltip>
		</Container>
	);
};

const Container = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 5px auto 20px;
	user-select: none;

	@media only screen and (min-width: 500px) {
		flex-direction: row;
	}
`;

const ConfirmationCheckbox = styled.div`
	display: flex;
	align-items: center;

	input {
		min-width: 16px;
		width: 16px;
		height: 16px;
		margin-right: 5px;
		cursor: pointer;
	}

	label {
		cursor: pointer;
	}
`;

const Tooltip = styled.div`
	outline: none;
	position: relative;
	display: flex;
	margin-top: 2px;

	i {
		outline: none;
		display: none;
	}

	p {
		margin: 0;
		padding: 5px;
		font-size: 14px;
		font-style: italic;
		text-align: center;
		border: 2px solid #a3b0c2;
		border-radius: 4px;
		color: #a3b0c2;
	}

	@media only screen and (min-width: ${TOOLTIP_BREAKPOINT + 'px'}) {
		margin-top: 0;
		margin-left: 5px;
		cursor: help;

		i {
			display: inline-block;

			&:is(:hover, :focus) + p {
				visibility: visible;
			}
		}

		p {
			visibility: hidden;
			position: absolute;
			bottom: 100%;
			left: 50%;
			padding: 8px;
			border: none;
			border-radius: 4px;
			font-size: 14px;
			font-style: normal;
			color: #fff;
			background-color: #333;
			transform: translateX(-50%);
			white-space: nowrap;
		}
	}
`;
